@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'lato', 'sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active-link {
  color: red;
}

/* Customize the scrollbar width */
  /* Note: The "w-[8px]" class is used to set the scrollbar width to 8px */
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Customize the scrollbar height */
  /* Note: The "h-[8px]" class is used to set the scrollbar height to 8px */
  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background:#FFF2CD;
  }

  ::-webkit-scrollbar-thumb {
    background: #737373;
    border-radius: 4px;
  }


  /* styles.css or a Tailwind CSS file */
.custom-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.3s ease;
  color: white;
  padding: 8px;
}

.custom-arrow:hover {
  color: #ffe512; /* Change to your desired hover color */
}

.custom-left-arrow {
  position: absolute;
  left: 0px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
}

.custom-right-arrow {
  position: absolute;
  right: 0px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
}
